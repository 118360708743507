import React from "react"
import { Navbar } from "react-bootstrap"
import "./static/bootstrap/bootstrap431dist/css/bootstrap.min.css"
import "./static/styles/navbarboot.css"

class Navigationbar extends React.Component {
  render() {
    return (
      <Navbar bg="light" expand="sm" id="custom-navbar">
        <Navbar.Brand className="navbarlink" href="/">
          SACHS
        </Navbar.Brand>
      </Navbar>
    )
  }
}

export default Navigationbar

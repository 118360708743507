/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Navigation from "./navbarboot"

import "./layout.css"

const footerStyles = {
  display: `flex`,
  flexDirection: `column`,
  padding: `1rem 0.5rem`,
  textAlign: `center`,
  color: `#f6f6f6`,
  backgroundColor: `#0f7171`,
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <title>{data.site.siteMetadata.title}</title>
      <Navigation />
      <div style={{ paddingTop: "1rem" }}>
        <main>{children}</main>
        <footer style={footerStyles}>
          <p style={{ margin: `1rem 0` }}>HELP SPREAD THE WORD</p>
          <p style={{ fontSize: `0.9rem` }}>
            If you know someone who may benefit from what you see, please feel
            free to forward this page to them.
          </p>
          © {new Date().getFullYear()}, Built by
          {` `}
          <a href="/">SACHS</a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
